/* css for appearance preview */
/* class pre name "bat-bis" means (bat back in stock) */

.bat-bis-root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* skeleton */
.bat-bis-skeleton-button {
  height: 24px;
  width: 120px;
  background: #e4e5e7;
  border-radius: 3px;
}
.bat-bis-skeleton-paragraph {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bat-bis-skeleton-paragraph-item {
  height: 8px;
  background: #e4e5e7;
  border-radius: 3px;
}

/* text */
.bat-bis-text-center {
  text-align: center;
}
.bat-bis-text-light {
  color: #707070;
}
.bat-bis-body-12 {
  font-size: 12px;
}
.bat-bis-body-13 {
  font-size: 13px;
}
.bat-bis-body-14 {
  font-size: 14px;
}
.bat-bis-heading-16 {
  font-size: 16px;
  font-weight: 500;
}

/* img */
.bat-bis-img-full {
  width: 100%;
  height: auto;
}

/* stack code */
.bat-bis-block-stack {
  display: flex;
  flex-direction: column;
}
.bat-bis-inline-stack {
  display: flex;
  flex-direction: row;
}
.bat-bis-items-center {
  align-items: center;
}
.bat-bis-justify-center {
  justify-content: center;
}
.bat-bis-gap-100 {
  gap: 4px;
}
.bat-bis-gap-200 {
  gap: 8px;
}
.bat-bis-gap-300 {
  gap: 12px;
}
.bat-bis-gap-400 {
  gap: 12px;
}

/* divider */
.bat-bis-divider {
  border-bottom: 1px solid #dedede;
}

/* form */
.bat-bis-form-label {
  font-size: 14px;
}
.bat-bis-form-label.required:after {
  content: " *";
  color: red;
}
.bat-bis-text-field {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #dedede;
  font-size: 14px;
  outline: none;
}
.bat-bis-input-radio,
.bat-bis-input-check {
  accent-color: #000;
  width: 16px;
  height: 16px;
}

/* banner */
.bat-bis-banner-success {
  background-color: #cdfee1;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: start;
  gap: 8px;
}
.bat-bis-banner-success .bat-bis-banner-text {
  font-size: 13px;
  color: #0c5132;
}
.bat-bis-banner-warning {
  background-color: #fff1e3;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: start;
  gap: 8px;
}
.bat-bis-banner-warning .bat-bis-banner-text {
  font-size: 13px;
  color: #5e4200;
}
.bat-bis-banner-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* created by */
.bat-bis-created-by {
  padding: 8px;
  background-color: #fafaff;
  font-size: 11px;
  color: #707070;
  text-align: center;
}

/* resource item */
.bat-bis-resource-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.bat-bis-resource-item-thumbnail {
  height: 60px;
  width: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #dedede;
  border-radius: 8px;
}
.bat-bis-resource-item-name {
  font-size: 13px;
  font-weight: 600;
  color: #4a4a4a;
}
.bat-bis-resource-item-price {
  font-size: 13px;
  color: #b5b5b5;
  margin-left: auto;
}

/* popup */
.bat-bis-modal {
  position: fixed;
  z-index: 999;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bat-bis-modal-content {
  background-color: #fff;
  position: relative;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 520px;
  max-height: 90vh;
  overflow-y: auto;
}
.bat-bis-modal-close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #eee;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bat-bis-modal-close-btn:hover {
  background-color: #ddd;
}
.bat-bis-floating-button {
  position: fixed;
  top: 50%;
  right: 0;
  rotate: -90deg;
  transform: translate(50%, 100%);
}


/* backinstock setting page */
.back-in-stock-setting-div .icon{
  display: flex;
  background: #f4f6f8;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: transparent;
  border-radius: 3px;
}

.back-in-stock-setting-div .icon{
  fill: #919eab;
}


.back-in-stock-right-card-title-style{
  font-weight: 650;
}
